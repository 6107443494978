import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/codingRegistration/CodingRegistration.css";
import "../css/codingRegistration/CodingRegistrationMediaQuery.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../../components/Loader";
import { FiCode,  FiCheckCircle, FiCalendar, FiCheck, FiClock, FiUsers,FiMapPin, FiAlertTriangle , FiAlertCircle } from "react-icons/fi";
import {   } from "react-icons/fi";


export default function CodingRegistrationForm() {
  const [inputs, setInputs] = useState({
    fullname: "",
    email: "",
    phone_number: "",
    years_of_experience: "",
    proficiency_level: "",
    programming_languages: [],
    other_languages: "",
    leetcode_attempted: "",
    leetcode_problems_solved: "",
    goals: [],
    other_goals: "",
    leetcode_profile: "",
    hackerrank_profile: "",
    codeforces_profile: "",
    github_profile: "",
    other_profiles: ""
  });

  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  // Options config
  const formConfig = {
    experienceOptions: [
      "Less than 1 year",
      "1-2 years", 
      "3-5 years",
      "More than 5 years"
    ],
    proficiencyOptions: [
      "Beginner (Just starting out)",
      "Intermediate (Familiar with basic concepts)",
      "Advanced (Comfortable with most concepts)"
    ],
    languageOptions: ["Java", "Python", "C++", "JavaScript"],
    goalOptions: [
      "Preparing for technical interviews",
      "Improving problem-solving skills",
      "Academic purposes",
      "Personal interest"
    ],
    leetcodeOptions: ["Less than 10", "10-50", "50-100", "100+"]
  };

  const validateField = (name, value) => {
    let error = "";
    switch(name) {
      case 'email':
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) error = "Invalid email format";
        break;
      case 'phone_number':
        if (!/^\d{10}$/.test(value)) error = "Phone must be 10 digits";
        break;
      case 'years_of_experience':
      case 'proficiency_level':
      case 'leetcode_attempted':
        if (!value) error = "This field is required";
        break;
      case 'programming_languages':
      case 'goals':
        if (value.length === 0) error = "Select at least one option";
        break;
    }
    setErrors(prev => ({ ...prev, [name]: error }));
    return !error;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      const newArray = checked
        ? [...inputs[name], value]
        : inputs[name].filter(item => item !== value);
      setInputs(prev => ({ ...prev, [name]: newArray }));
      validateField(name, newArray);
    } else {
      setInputs(prev => ({ ...prev, [name]: value }));
      validateField(name, value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    
    // Validate all fields
    const validations = [
      validateField('fullname', inputs.fullname),
      validateField('email', inputs.email),
      validateField('phone_number', inputs.phone_number),
      validateField('years_of_experience', inputs.years_of_experience),
      validateField('proficiency_level', inputs.proficiency_level),
      validateField('programming_languages', inputs.programming_languages),
      validateField('goals', inputs.goals),
      validateField('leetcode_attempted', inputs.leetcode_attempted),
    ];

    if (validations.every(v => v)) {
      try {
        const response = await axios.post("https://www.ieeevitpune.com/codingsess", inputs);
        if (response.data.success) {
          toast.success("Registration successful! 🎉");
          setTimeout(() => navigate("/"), 2000);
        }
      } catch (err) { 
        toast.error(err.response?.data?.msg || "Registration failed 😢");
      }
    }
    setLoader(false);
  };

  return (
    <div className="coding-registration">
      {loader && <Loader />}
      
      <div className="form-header">
  <h1>🚀 Coding & DSA Mastery Sessions</h1>
  <p className="subheading">
    Master <span className="highlight">LeetCode Blind 75</span> with C++/Java + Core DSA Concepts
  </p>

  <div className="session-highlights">
    <div className="highlight-card">
      <div className="highlight-main">
        <FiCode className="main-icon" />
        <div>
          <h2>Transform Your Coding Skills</h2>
          <p className="program-details">
            6-Week Intensive Program Covering:
          </p>
          <ul className="features-list">
            <li><FiCheckCircle /> Beginner to Advanced DSA Concepts</li>
            <li><FiCheckCircle /> LeetCode Problem Solving Strategies</li>
            <li><FiCheckCircle /> Interview Preparation Techniques</li>
            <li><FiCheckCircle /> Hands-on Coding Practice Sessions</li>
          </ul>
        </div>
      </div>

      <div className="detail-grid">
        <div className="detail-block">
          <FiCalendar className="icon" />
          <div>
            <p className="detail-label">Schedule</p>
            <p className="detail-value">Mon/Wed/Fri</p>
            <p className="detail-sub">Starting 24th March</p>
          </div>
        </div>

        <div className="detail-block">
          <FiClock className="icon" />
          <div>
            <p className="detail-label">Timing</p>
            <p className="detail-value">5:00-6:30 PM</p>
            <p className="detail-sub">1.5 Hours/Session</p>
          </div>
        </div>

        <div className="detail-block">
          <FiMapPin className="icon" />
          <div>
            <p className="detail-label">Venue</p>
            <p className="detail-value">1222 & 1224</p>
            <p className="detail-sub">VIT Pune Campus</p>
          </div>
        </div>

        <div className="detail-block">
          <FiUsers className="icon" />
          <div>
            <p className="detail-label">Open For</p>
            <p className="detail-value">All Students</p>
            <p className="detail-sub">No Prerequisites</p>
          </div>
        </div>
      </div>
    </div>

    <div className="cta-box">
      <FiAlertTriangle className="cta-icon" />
      <p className="cta-text">
        <strong>Limited Seats Available!</strong><br />
        Register now to secure your spot in this comprehensive program. 
        Perfect for placement preparation and skill enhancement! ✨
      </p>
    </div>
  </div>
</div>

      <form onSubmit={handleSubmit}>
        {/* Personal Info Section */}
        <fieldset className="form-section">
          <legend>Personal Information</legend>
          <div className="form-grid">
            <div className={`input-group ${errors.fullname ? 'error' : ''}`}>
              <label>Full Name <span>*</span></label>
              <input
                name="fullname"
                value={inputs.fullname}
                onChange={handleChange}
                placeholder="Your Full Name"
                className="other-input styled-input"
              />
              {errors.fullname && <span className="error-message"><FiAlertCircle /> {errors.fullname}</span>}
            </div>

            <div className={`input-group ${errors.email ? 'error' : ''}`}>
              <label>Email <span>*</span></label>
              <input
                type="email"
                name="email"
                value={inputs.email}
                onChange={handleChange}
                placeholder="Your vit.edu email"
                className="other-input styled-input"
              />
              {errors.email && <span className="error-message"><FiAlertCircle /> {errors.email}</span>}
            </div>

            <div className={`input-group ${errors.phone_number ? 'error' : ''}`}>
              <label>Phone Number <span>*</span></label>
              <input
                name="phone_number"
                value={inputs.phone_number}
                onChange={handleChange}
                placeholder="Your WhatsApp Number"
                maxLength="10"
                className="other-input styled-input"
              />
              {errors.phone_number && <span className="error-message"><FiAlertCircle /> {errors.phone_number}</span>}
            </div>
          </div>
        </fieldset>

        {/* Programming Experience */}
        <fieldset className="form-section">
          <legend>Coding Experience</legend>
          <div className="form-grid">
            <div className={`radio-group ${errors.years_of_experience ? 'error' : ''}`}>
              <label>Years of Experience <span>*</span></label>
              {formConfig.experienceOptions.map(opt => (
                <label key={opt} className="radio-option">
                  <input
                    type="radio"
                    name="years_of_experience"
                    value={opt}
                    checked={inputs.years_of_experience === opt}
                    onChange={handleChange}
                  />
                  <span className="custom-radio"></span>
                  {opt}
                </label>
              ))}
              {errors.years_of_experience && <span className="error-message"><FiAlertCircle /> {errors.years_of_experience}</span>}
            </div>

            <div className={`radio-group ${errors.proficiency_level ? 'error' : ''}`}>
              <label>Proficiency Level <span>*</span></label>
              {formConfig.proficiencyOptions.map(opt => (
                <label key={opt} className="radio-option">
                  <input
                    type="radio"
                    name="proficiency_level"
                    value={opt}
                    checked={inputs.proficiency_level === opt}
                    onChange={handleChange}
                  />
                  <span className="custom-radio"></span>
                  {opt}
                </label>
              ))}
              {errors.proficiency_level && <span className="error-message"><FiAlertCircle /> {errors.proficiency_level}</span>}
            </div>
          </div>
        </fieldset>

        {/* Programming Languages */}
        <fieldset className="form-section">
          <legend>Technical Skills</legend>
          <div className={`checkbox-group ${errors.programming_languages ? 'error' : ''}`}>
            <label>Languages You Know <span>*</span></label>
            <div className="checkbox-grid">
              {formConfig.languageOptions.map(lang => (
                <label key={lang} className="checkbox-option">
                  <input
                    type="checkbox"
                    name="programming_languages"
                    value={lang}
                    checked={inputs.programming_languages.includes(lang)}
                    onChange={handleChange}
                  />
                  <span className="custom-checkbox"><FiCheck /></span>
                  {lang}
                </label>
              ))}
            </div>
            <input
              type="text"
              name="other_languages"
              value={inputs.other_languages}
              onChange={handleChange}
              placeholder="Other languages (comma separated)"
              className="other-input styled-input"
            />
            {errors.programming_languages && <span className="error-message"><FiAlertCircle /> {errors.programming_languages}</span>}
          </div>
        </fieldset>

        {/* LeetCode Experience Section */}
<fieldset className="form-section">
  <legend>LeetCode Experience</legend>
  <div className="form-grid">
    <div className={`radio-group ${errors.leetcode_attempted ? 'error' : ''}`}>
      <label>Attempted LeetCode Before? <span>*</span></label>
      <div className="toggle-group">
        {['Yes', 'No'].map(opt => (
          <label key={opt} className="radio-option">
            <input
              type="radio"
              name="leetcode_attempted"
              value={opt}
              checked={inputs.leetcode_attempted === opt}
              onChange={handleChange}
            />
            <span className="custom-radio"></span>
            {opt}
          </label>
        ))}
      </div>
      {errors.leetcode_attempted && 
        <span className="error-message"><FiAlertCircle /> {errors.leetcode_attempted}</span>}
    </div>

    {inputs.leetcode_attempted === "Yes" && (
      <div className="input-group">
        <label>Problems Solved</label>
        <select
          name="leetcode_problems_solved"
          value={inputs.leetcode_problems_solved}
          onChange={handleChange}
          className="styled-select"
        >
          <option value="">Select Range</option>
          {formConfig.leetcodeOptions.map(opt => (
            <option key={opt} value={opt}>{opt}</option>
          ))}
        </select>
      </div>
    )}
  </div>
</fieldset>

        {/* Goals Section */}
        <fieldset className="form-section">
          <legend>Learning Goals</legend>
          <div className={`checkbox-group ${errors.goals ? 'error' : ''}`}>
            <label>Your Goals <span>*</span></label>
            <div className="checkbox-grid">
              {formConfig.goalOptions.map(goal => (
                <label key={goal} className="checkbox-option">
                  <input
                    type="checkbox"
                    name="goals"
                    value={goal}
                    checked={inputs.goals.includes(goal)}
                    onChange={handleChange}
                  />
                  <span className="custom-checkbox"><FiCheck /></span>
                  {goal}
                </label>
              ))}
            </div>
            <div className="input-group"></div>
            <input
              type="text"
              name="other_goals"
              value={inputs.other_goals}
              onChange={handleChange}
              placeholder="Other goals"
              className="other-input styled-input"
            />
            {errors.goals && <span className="error-message"><FiAlertCircle /> {errors.goals}</span>}
          </div>
        </fieldset>

        {/* Coding Profiles Section */}
<fieldset className="form-section">
  <legend>Coding Profiles</legend>
  <div className="form-grid">
    <div className="input-group">
      <label>LeetCode Profile</label>
      <input
        type="url"
        name="leetcode_profile"
        value={inputs.leetcode_profile}
        onChange={handleChange}
        placeholder="https://leetcode.com/yourprofile"
        className="other-input styled-input"
      />
    </div>

    <div className="input-group">
      <label>HackerRank Profile</label>
      <input
        type="url"
        name="hackerrank_profile"
        value={inputs.hackerrank_profile}
        onChange={handleChange}
        placeholder="https://hackerrank.com/yourprofile"
        className="other-input styled-input"
      />
    </div>

    <div className="input-group">
      <label>Codeforces Profile</label>
      <input
        type="url"
        name="codeforces_profile"
        value={inputs.codeforces_profile}
        onChange={handleChange}
        placeholder="https://codeforces.com/yourprofile"
        className="other-input styled-input"
      />
    </div>

    <div className="input-group">
      <label>GitHub Profile</label>
      <input
        type="url"
        name="github_profile"
        value={inputs.github_profile}
        onChange={handleChange}
        placeholder="https://github.com/yourprofile"
        className="other-input styled-input"
      />
    </div>

    <div className="input-group">
      <label>Other Profiles</label>
      <input
        type="text"
        name="other_profiles"
        value={inputs.other_profiles}
        onChange={handleChange}
        placeholder="CodeSignal, Codewars, etc."
class="other-input styled-input"
      />
    </div>
  </div>
</fieldset>

{/* Add this before the submit button */}
<div className="whatsapp-notice">
  <FiAlertCircle className="notice-icon" />
  <p>
    <strong>Important:</strong> Before submitting, join our WhatsApp group for updates:{" "}
    <a 
      href="https://chat.whatsapp.com/EVa8Q9UYvFl9iv9rnLzPlp" 
      target="_blank" 
      rel="noopener noreferrer"
      className="whatsapp-link"
    >
      Click to Join WhatsApp Group
    </a>. <br />Group joining is compulsory for registration.
    <hr />
     For any queries contact : <br />
    Nidhish Wakodikar : 9511838841 <br /> 
    Nishant Lanjewar : 9075110655
  </p>
</div>

        <button type="submit" className="submit-button">
          Complete Registration <FiCheck className="button-icon" />
        </button>
      </form>
    </div>
  );
}