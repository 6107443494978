import React from "react";
import Particle from "../../components/Particle";
import CodingRegistrationForm from "../components/CodingForm";
import "../css/codingRegistration/CodingRegistration.css";

export default function CodingSessionRegistration() {
  return (
    <div className="signUp">
      <Particle />
      <CodingRegistrationForm />
    </div>
  );
}