import medha from "../assets/images/aboutUs/Faculty/medha.png";
import jabade from "../assets/images/aboutUs/Faculty/vaishali2.png";

const faculty = [
  {
    imagec: medha,
    namec: "Prof. Dr. Medha Wyawahare",
    positionc: "Branch Mentor",
  },
  {
    imagec: jabade,
    namec: "Prof. Dr. Vaishali Jabade",
    positionc: "Branch Counselor",
  },
];

export default faculty;
