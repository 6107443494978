import { NavBar } from "./components/NavBar2";
import HomePage from "./pages/HomePage";
import EventsPage from "./pages/EventsPage";
import AboutUs from "./pages/AboutUs";
import { Activities } from "./pages/Activities";
import Login from "./pages/Login";
import UserProfile from "./pages/UserProfile";
// import IndividualEvents from "./pages/IndividualEvents";
// import AdminDash from "./pages/AdminDash";
// import VerifyRegistration from "./pages/VerifyRegistration";
// import MemberVerify from "./pages/MemberVerify";
import LoginUsingOTP from "./pages/LoginUsingOtp";
import EnterOTP from "./pages/EnterOTP";
import SignUp from "./pages/SignUp";
// import TechTalkRegistration from "./TechSummit/pages/TechTalkRegistration";
// import HackathonRegistration from "./TechSummit/pages/HackathonRegistration";
// import HackathonSubmission from "./TechSummit/pages/HackathonSubmission";
// import ResearchSessionRegistration from "./TechSummit/pages/ResearchSessionRegistration";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import EmailSend from "./pages/EmailSend";
import Developers from "./pages/DevelopersData";
import Footer from "./components/Footer";
import ErrorPage from "./pages/ErrorPage";
import { QuestionPage } from "./pages/QuestionPage";

// import TechSummit from "./TechSummit/pages/TsHomePage";
// import TsSpeakers from "./TechSummit/pages/TS_Speakers";
// import TsExpo from "./TechSummit/pages/TsExpo";
// import TsWorkshop from "./TechSummit/pages/TsWorkshop";
// import TsIdeathon from "./TechSummit/pages/TsIdeathon";
// import TsTicketDesktop from "./TechSummit/components/TsTicketDesktop";
// import TsTicketMobile from "./TechSummit/components/TsTicketMobile";
// import Scanner from "./TechSummit/components/Scanner";
// import TsTicketNotFound from "./TechSummit/pages/TsTicketNotFound";

// import NFCVR from "./TechSummit/pages/NFCVR";
// import NFCQC from "./TechSummit/pages/NFCQC";
// import NFCDA from "./TechSummit/pages/NFCDA";
// import NFCAI from "./TechSummit/pages/NFCAI";
// import NFCPD from "./TechSummit/pages/NFCPD";
// import NFCTL from "./TechSummit/pages/NFCTL";

// import TechSummit25 from "./TechSummit25/pages/TsHomePage";
// import HackathonDetails from "./TechSummit25/pages/HackathonDetails";
// import HackathonRegistration from "./TechSummit25/pages/HackathonRegistration";
// import CodeZestDetails from "./TechSummit25/pages/CodeZestDetails";
// import CodeZestPaymentInstructions from "./TechSummit25/pages/CodeZestPaymentInstructions";
// import CodeZestRegistration from "./TechSummit25/pages/CodeZestRegistration";
import CodingSessionRegistration from "./TechSummit25/pages/CodingSessionRegistration";

// import HackathonAdminDash from "./pages/HackathonAdminDash";
// import CodeZestAdminDash from "./pages/CodeZestAdminDash";

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";
// import TsTicket from "./TechSummit/pages/TsTicket";
import TSPanel from "./TechSummit/components/TSPanel";

function App() {
  const getScrollValue = () => {
    const scrollValue = window.scrollY;
    return scrollValue;
  };

  const trackingId = "G-C21NGKVNJK";
  ReactGA.initialize(trackingId);

  const [loggedIn, setLoggedIn] = useState(false);

  const location = useLocation();

  const useScrollValue = () => {
    const [scrollValue, setScrollValue] = useState(getScrollValue());

    useEffect(() => {
      function handleScroll() {
        setScrollValue(getScrollValue());
      }

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return scrollValue;
  };

  const scrollValue = useScrollValue();

  return (
    <div>
      {location.pathname !== "/admin/codezest" &&
      location.pathname !== "/admin/hackathon" ? (
      <NavBar
        scrollValue={scrollValue}
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
      />
      ) : null}

      <ToastContainer />

      <Routes>
        {/* Pages to show to user without Login */}
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/Events" element={<EventsPage />}></Route>
        {/* <Route
          path="/event/getevent/:p1"
          element={<IndividualEvents />}
        ></Route> */}
        <Route path="/AboutUs" element={<AboutUs />}></Route>
        <Route path="/Activities" element={<Activities />}></Route>
        <Route path="/Developers" element={<Developers />}></Route>

        {/* Pages to show to user for Login */}
        <Route
          path="/Login"
          element={<Login setLoggedIn={setLoggedIn} />}
        ></Route>
        <Route path="/LoginUsingOTP" element={<LoginUsingOTP />}></Route>
        <Route path="/SignUp" element={<SignUp />}></Route>
        {/* <Route path="/TechTalkRegistration" element={<TechTalkRegistration />}></Route> */}
        {/* <Route path="/HackathonRegistration" element={<HackathonRegistration />}></Route> */}
        {/* <Route path="/HackathonSubmission" element={<HackathonSubmission />}></Route> */}
        {/* <Route path="/ResearchSessionRegistration" element={<ResearchSessionRegistration />}></Route> */}
        <Route path="/ForgotPassword" element={<ForgotPassword />}></Route>
        <Route
          path="/ResetPassword/:p1/:p2"
          element={<ResetPassword />}
        ></Route>
        <Route path="/VerifyEmail" element={<EnterOTP />}></Route>
        <Route path="/EmailSend" element={<EmailSend />}></Route>
        <Route path="/enterOTP" element={<EnterOTP />}></Route>
        <Route
          path="/Questions"
          element={<QuestionPage loggedIn={loggedIn} />}
        ></Route>

        {/* Pages to show to user after Login */}
        <Route
          path="/UserProfile"
          element={<UserProfile setLoggedIn={setLoggedIn} />}
        ></Route>

        {/* Pages to show to Admin */}
        {/* <Route path="/admin/AdminPage" element={<AdminDash />}></Route>
        <Route
          path="/admin/VerifyRegistration/:p1"
          element={<VerifyRegistration />}
        ></Route>
        <Route path="/admin/MemberVerify" element={<MemberVerify />}></Route> */}
        {/* <Route path="/admin/TicketScanner" element={<Scanner />}></Route> */}
        {/* <Route path="/admin/hackathon" element={<HackathonAdminDash />} />
        <Route path="/admin/codezest" element={<CodeZestAdminDash />} /> */}

        {/* TS Pages */}
        {/* <Route path="IEEEMonth" element={<TechSummit />}></Route>
        <Route path="IEEEMonth/TechTalk" element={<TsSpeakers />}></Route>
        <Route path="IEEEMonth/Expo" element={<TsExpo />}></Route>
        <Route path="IEEEMonth/ResearchSessions" element={<TsWorkshop />}></Route>
        <Route path="IEEEMonth/Hackathon" element={<TsIdeathon />}></Route> */}
        {/* <Route path="IEEEMonth/Ticket" element={<TsTicket />}></Route>
        <Route
          path="IEEEMonth/Ticket/NotFound"
          element={<TsTicketNotFound />}
        ></Route> */}

        {/* <Route path="IEEEMonth/NFC/VR" element={<NFCVR />}></Route>
        <Route path="IEEEMonth/NFC/QC" element={<NFCQC />}></Route>
        <Route path="IEEEMonth/NFC/DA" element={<NFCDA />}></Route>
        <Route path="IEEEMonth/NFC/AI" element={<NFCAI />}></Route>
        <Route path="IEEEMonth/NFC/PD" element={<NFCPD />}></Route>
        <Route path="IEEEMonth/NFC/TL" element={<NFCTL />}></Route> */}

        {/* TS25 Pages */}
        {/* <Route path="/TechSummit25" element={<TechSummit25 />}></Route>
        <Route path="/TechSummit25/Hackathon" element={< HackathonDetails/>} />
        <Route path="/TechSummit25/HackathonRegistration" element={< HackathonRegistration/>} />
        <Route path="/TechSummit25/CodeZest" element={< CodeZestDetails/>} /> 
        <Route path="/TechSummit25/CodeZestPaymentInstructions" element={< CodeZestPaymentInstructions/>} /> 
        <Route path="/TechSummit25/CodeZestRegistration" element={< CodeZestRegistration/>} /> */}
        <Route path="/CodingSessionRegistration" element={< CodingSessionRegistration/>} />

        {/* Error Page */}
        <Route
          path="/PageNotFound"
          element={<ErrorPage to="/PageNotFound" replace />}
        />
        <Route path="*" element={<Navigate to="/PageNotFound" replace />} />
      </Routes>

      {location.pathname !== "/admin/AdminPage" &&
      location.pathname !== "/admin/VerifyRegistration/:p1" &&
      location.pathname !== "/admin/MemberVerify" &&
      location.pathname !== "/userProfile" &&
      location.pathname !== "/admin/TicketScanner" &&
      location.pathname !== "/admin/hackathon" &&
      location.pathname !== "/admin/codezest" &&
      location.pathname !== "/PageNotFound" ? (
        <Footer />
      ) : null}
    </div>
  );
}

export default App;
