import React from "react";
import Particle from "../components/Particle";
import ResetPasswordForm from "../components/ResetPasswordForm";

export default function ResetPassword() {
  return (
    <div className="signUp">
      <Particle />
      <ResetPasswordForm />
    </div>
  );
}
