import React from "react";
import "../css/activities/Activities.css";
import research from "../assets/images/activities/search.gif";
import codingBoy from "../assets/images/activities/coding_table.gif";
import team from "../assets/images/activities/team.gif";
import coding_boy from "../assets/images/activities/boy_coding.gif";
import blogs from "../assets/images/activities/blogs.gif";
import Activities_gif from "../assets/images/activities/activities.gif";
import slides from "../data/Blogs_file.json";
import { Carousel } from "../components/Carousel2";

export function Activities() {
  return (
    <div className="Page">
      <div className="activities">
        ACTIVITIES
        <img src={Activities_gif} className="activities_gif" alt="" />
      </div>
      {/* Research club div */}
      <div>
        <div className="row_div">
          <div className="gif_div">
            <img src={research} alt="research" className="research_gif" />
          </div>

          <div className="col_div">
            <div className="title1">RESEARCH CLUB</div>
            <div className="para_box">
              <p className="para">
                You have an idea, we have a platform. IEEE as a global
                organization has been successful in uplifting the research
                quality of the students, as well as elevating their innovative
                skills. With a global network of researchers and engineers, our
                club provides an unparalleled platform for collaboration and
                knowledge sharing, as we support the next generation of
                innovators through our paper reading sessions and industrial
                visits.
              </p>
            </div>
          </div>
        </div>

        <div className="row_div">
          <div className="youtube_box">
            <iframe
              src="https://www.youtube.com/embed/L9d35XH6icY?list=PLfHF1gQx_tdATmheQwKmnAMJS30Vh-ZqV"
              title="Arrays"
              frameBorder="0"
              className="youtube_box_iframe"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="gif_div">
            <img src={codingBoy} alt="codingBoy" className="codingBoy_gif" />
          </div>
        </div>
      </div>

      {/* Coding club div */}
      <div>
        <div className="row_div">
          <div className="gif_div">
            <img src={team} alt="team" className="team_gif" />
          </div>
          <div className="col_div">
            <div className="title">CODING CLUB</div>
            <div className="para_box">
              <p className="para">
                Our mission is to create a fun and supportive environment where
                we develop programming and statistics skills together. We want
                to replace statistics anxiety and code fear with inspiration and
                motivation to learn, and here we will share our experience. The
                main vision of the club is to improve the art of competitive
                programming skills in the students and to make them ready for
                renowned competitions like ACM-ICPC, Google code jam, various
                Hackathons conducted at national and International levels etc.
              </p>
            </div>
          </div>
        </div>
        <div className="row_div">
          <div className="youtube_box">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/L9d35XH6icY?list=PLfHF1gQx_tdATmheQwKmnAMJS30Vh-ZqV"
              title="Arrays"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="gif_div">
            <img src={coding_boy} alt="coding_boy" className="coding_boy_gif" />
          </div>
        </div>
      </div>

      {/* Blogs */}
      {/* <div>
        <div className="row_div">
          <div className="gif_div_2">
            <img src={blogs} alt="blogs" className="blogs_gif" />
          </div>
          <div className="col_div">
            <div className="title_blog">BLOGS</div>
            <Carousel slides={slides} />
          </div>
        </div>
      </div> */}
    </div>
  );
}
