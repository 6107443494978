import React from "react";
import { useEffect } from "react";
import "../css/homePage/HomePage.css";
import Card from "../components/Card";
import DomainLaptop from "../components/DomainLaptop";
import DomainMobile from "../components/DomainMobile";
import Gallery from "../components/Gallery";
import Particle from "../components/Particle";
import { HomePageHeader } from "../components/HomePageHeader";
import HomePageTechSummitCard from "../components/HomePageTechSummitCard";

export default function HomePage() {
  useEffect(() => {
    // scroll page to top with smooth animation
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="HomeBg">
      <Particle />
      <HomePageHeader />
      <Card />
      <HomePageTechSummitCard />
      <div className="DomainLaptop">
        <DomainLaptop />
      </div>
      <div className="DomainMobile">
        <DomainMobile />
      </div>
      <Gallery />
         
    </div>
  );
}
