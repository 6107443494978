import React from "react";
import OTPcard from "../components/OTPcard";
import Particle from "../components/Particle";

export default function EnterOTP(props) {
  return (
    <div className="signUp">
      <Particle />
      <OTPcard props={props} />
    </div>
  );
}
