import React from "react";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import Particle from "../components/Particle";

export default function ForgotPassword() {
  return (
    <div className="signUp">
      <Particle />
      <ForgotPasswordForm />
    </div>
  );
}
