import React from "react";
import Particle from "../components/Particle";
import SignUpCard from "../components/SignUpForm";
import "../css/signUp/SignUp.css";

export default function SignUp() {
  return (
    <div className="signUp">
      <Particle />
      <SignUpCard />
    </div>
  );
}
