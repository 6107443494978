import React from "react";
import "../css/footer/Footer2.css";
import discord_logo from "../assets/images/footer/discordLogoSVG.svg";
import instagram_logo from "../assets/images/footer/instagram_logo.svg";
import linkedin_logo from "../assets/images/footer/linkedin_logo.svg";
import youtube_logo from "../assets/images/footer/youtube_logo.svg";
import IEEE_logo1 from "../assets/images/footer/IEEE_logo2.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_parent">
      <div className="footerbg">
        <div className="footer1">
          <div className="logo">
            <img className="IEEE_logo" src={IEEE_logo1} alt="IEEE_logo" />
          </div>
          <div className="follow_us">
            <div className="social_media">
              <h2 className="footerHeading1">Follow Us</h2>

              <a
                href="https://www.linkedin.com/company/ieee-student-branch-vit-pune/mycompany/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="linkedinLogo"
                  src={linkedin_logo}
                  alt="linkedin_logo"
                />
              </a>
              <a
                href="https://discord.gg/QFVGbyk5"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="facebookLogo"
                  src={discord_logo}
                  alt="discord_logo"
                />
              </a>
              <a
                href="https://www.instagram.com/ieee_vit_studentbranch/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="instagramLogo"
                  src={instagram_logo}
                  alt="instagram_logo"
                />
              </a>
              <a
                href="https://www.youtube.com/@ieeestudentbranchvitpune6406"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="youtubeLogo"
                  src={youtube_logo}
                  alt="youtube_logo"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="FooterlastDiv">
        <p className="footerCopyrightLine">
          &#169; 2025 IEEE SB VIT PUNE | All Rights Reserved
        </p>
        <p className="footerDevelopersLine">
          Made with &#10084;&#65039; by
          <Link to="/Developers" className="developersLastLink">
            WEB TEAM
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Footer;
